import {creatShareContractLink} from "./create-share-contract-link";
import {contractEmailTemplate} from "./email-template";

import {Contract} from "../../../Contract.entity";

const SEND_EMAIL_ENDPOINT = "https://phu-thai-dev.cwr.cloud-app.icu/send-email/send"
export const sendEmail = (contract: Contract) => {
	const SHARED_LINK = creatShareContractLink(contract.id);
	const EMAIL_BODY = contractEmailTemplate(contract, SHARED_LINK);

	return postData(SEND_EMAIL_ENDPOINT, {
	to: [contract.customerEmail],
		subject: "Thư mời cộng tác soạn thảo Hợp đồng " + contract.soHopDong,
		body: EMAIL_BODY
	})
}

async function postData(url = '', data: any = {}) {

	const response = await fetch(url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(data) // body data type must match "Content-Type" header
	});
	return response.json(); // parses JSON response into native JavaScript objects
}