import type { FC } from "react";
import { Button, Result } from "antd";

const ResultErrorTimeout: FC = () => (
	<div style={{ backgroundColor: "#fff", margin: "auto", paddingTop: 50 }}>
		<Result
			status="403"
			title="Chia sẻ hết hạn"
			subTitle="Xin lỗi bạn, chia sẻ này có thể đã hết hạn, vui lòng email cho nhân sự làm việc với bạn để nhận được chia sẻ mới."
			// extra={<Button type="primary">Back Home</Button>}
		/>
	</div>
);

export default ResultErrorTimeout;
