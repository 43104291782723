import { Breadcrumb, Button, Card, Space, Spin, Table } from "antd";
import { useEffect, useState } from "react";
import {  Link } from "react-router-dom";
import {
  PlusOutlined,
  FileTextTwoTone,
  FolderOpenOutlined,
  EditOutlined
} from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";

import { getListContract } from "../../contract.api-service";
import { Contract } from "../../Contract.entity";
import ConfigLayout from "./components/ConfigLayout";
import ModalCreateContract from "./components/ModalCreateContract";

const FormatNumber = new Intl.NumberFormat('vi-VN')

const columns: ColumnsType<Contract> = [
  {
    title: "Số HĐ",
    dataIndex: "soHopDong",
    key: "soHopDong",
    responsive: ['md'],
  },
  {
    title: "Tên HĐ",
    dataIndex: "name",
    key: "name",
    render: (val, item) => (
      <Link to={"/config/update/" + item.id}>
        <FileTextTwoTone /> {val}
      </Link>
    ),
  },
  {
    title: "Khách hàng",
    dataIndex: "customerName",
    key: "customerName",
    responsive: ['md'],
  },
  {
    title: "Giá trị HĐ",
    dataIndex: "tongGiaTriHopDong",
    key: "tongGiaTriHopDong",
    render: (val) => (
      <div style={{ textAlign: "right" }}>
        {FormatNumber.format(val)}
      </div>
    ),
    responsive: ['md'],
  },
  {
    title: "Action",
    key: "action",
    render: (_, item) => (
      <Space size="middle">
        <Link to={"/config/update/" + item.id}><EditOutlined /> Sửa</Link>
        {/*<Link to={"/config/update/" + item.id}>Delete</Link>*/}
      </Space>
    ),
  },
];

export default function ListContractConfig() {
  const [list, setList] = useState<Contract[]>([]);
  const [loading, setLoading] = useState(false);
  const [isOpenModalCreate, setIsOpenModalCreate] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    getListContract()
      .then((result) => {
        console.log("ressul", result);
        setList(result.items);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  return (
    <ConfigLayout>
      {/* <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>
          <FolderOpenOutlined /> Hợp đồng
        </Breadcrumb.Item>
      </Breadcrumb> */}
      <Card
        title={
          <>
            <FolderOpenOutlined /> Danh sách Hợp đồng
          </>
        }
        extra={
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setIsOpenModalCreate(true)}
          >
            Tạo mới
          </Button>
        }
        style={{ marginTop: 20 }}
      >
        {loading ? (
          <Spin />
        ) : (
          <Table columns={columns} dataSource={list} pagination={false} />
        )}
      </Card>
      <ModalCreateContract
        isOpen={isOpenModalCreate}
        setIsOpen={setIsOpenModalCreate}
        reFetchData={fetchData}
      />
    </ConfigLayout>
  );
}
