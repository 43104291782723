import { Layout } from "antd";
import { RouterProvider, createHashRouter } from "react-router-dom";
import NotFoundPage from "./Components/NotFoundPage";
import CreateContract from "./Contract/pages/config/CreateContract";
import ListContractConfig from "./Contract/pages/config/ListContract";
import UpdateContract from "./Contract/pages/config/UpdateContract";
import ResultSuccess from "./Contract/pages/public/ResultSuccess";
import UpdateContractByCustomer from "./Contract/pages/public/UpdateContractByCustomer";
import ResultErrorTimeout from "./Contract/pages/public/ResultErrorTimeout";
import TableDemo from "./Contract/pages/config/TableDemo";

export default function Routers() {
  const { Content } = Layout;

  const router = createHashRouter([
    {
      path: "/",
      element: <div></div>,
    },
    // CONFIG
    {
      path: "/config",
      element: <ListContractConfig />,
    },
    {
      path: "/config/create",
      element: <CreateContract />,
    },
    {
      path: "/config/update/:contractId",
      element: <UpdateContract />,
    },
    {
      path: "/config-table-demo",
      element: <TableDemo />,
    },
    // PUBLIC
    {
      path: "/public",
      element: <ResultSuccess />,
    },
    {
      path: "/public/:contractId",
      element: <UpdateContractByCustomer />,
    },
    {
      path: "/public/:contractId/:expKey",
      element: <UpdateContractByCustomer />,
    },
    {
      path: "/public-result-timeout",
      element: <ResultErrorTimeout />,
    },
    // FALL BACK 404
    {
      path: "*",
      element: <NotFoundPage />,
    },
  ]);

  return (
    <Content style={{ backgroundColor: "#cfcfcf" }}>
      <RouterProvider router={router} />
    </Content>
  );
}
