import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

export default function NotFoundPage() {
  const nav = useNavigate();

  return (
    <div style={{ backgroundColor: "#fff", margin: "auto", paddingTop: 50 }}>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button type="primary" onClick={() => nav("/")}>
            Back Home
          </Button>
        }
      />
    </div>
  );
}
