import { Breadcrumb, Button, Form, Input, Space } from "antd";
import { useState } from "react";
import { useHref, useNavigate } from "react-router-dom";
import { createContract } from "../../contract.api-service";
import ContractForm from "./components/ContractForm";

const CreateContract: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const nav = useNavigate();

  const onFinish = async (values: any) => {
    setLoading(true);
    console.log("Success:", values);
    await createContract(values);
    nav(-1);
    setLoading(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      name="basic"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
      disabled={loading}
    >
      <ContractForm />
      <Form.Item wrapperCol={{ offset: 10, span: 24 }}>
        <Button type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateContract;
