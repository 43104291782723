import {
  Button,
  Card,
  Col,
  DatePicker,
  Descriptions,
  Divider,
  Form,
  Input,
  InputNumber,
  notification,
  Row,
} from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { SendOutlined } from "@ant-design/icons";

import styles from "./UpdateContractByCustomer.module.css"

import {
  createCustomerEditHistory,
  getContract,
  updateContract,
} from "../../contract.api-service";

import { Contract } from "../../Contract.entity";
import ListHistoryOnContract from "../config/components/ListHistoryOnContract";
import { formatDateTime } from "../../../formatDateTime.ultis";

const { TextArea } = Input;

const UpdateContractByCustomer: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState<Contract>();
  const { contractId, expKey } = useParams();
  const nav = useNavigate();


  useEffect(() => {
    if (expKey){
      const NOW_TIME = new Date().getTime()
      const exp = parseInt(expKey);
      if(exp > 0 && (((exp - 99999) / 2) > NOW_TIME) ) {
        // "ok"
        if (contractId) getContractNeedUpdate(contractId);
      } else {
        nav("/public-result-timeout", {replace: true})
      }
    } else {
      nav("/public-result-timeout", {replace: true})
    }
  }, [expKey, contractId]);


  const getContractNeedUpdate = (contractId: string) => {
    setLoading(true);
    getContract(contractId)
      .then((result) => {
        setItem(result);

        if (result.ngayBatDauDuKien)
          result.ngayBatDauDuKien = dayjs(result.ngayBatDauDuKien);
        if (result.ngayKetThucDuKien)
          result.ngayKetThucDuKien = dayjs(result.ngayKetThucDuKien);
        // điền giá trị vào form
        form.setFieldsValue(result);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const onFinish = async (values: Omit<Contract, "id">) => {
    if (!contractId || !item) return;
    setLoading(true);

    const diffObj = {};
    let hasDiff = false;

    // kiểm tra xem có thay đổi gì không
    for (const key in values) {
      // @ts-ignore
      if (values[key] !== item[key] && key !== "changeNote") {
        // lưu lại giá trị trước khi thay đổi
        // @ts-ignore
        diffObj[key] = item[key];
        hasDiff = true;
      }
    }

    // B1: tạo lịch sử trước
    await createCustomerEditHistory({
      // @ts-ignore
      contract: item.id,
      customerName: item.customerName || "Nguyễn Văn Tùng",
      // @ts-ignore
      description: values.changeNote,
      diff: hasDiff ? JSON.stringify(item) : null,
      diffAfter: hasDiff
        ? JSON.stringify({ ...values, changeNote: null })
        : null,
    });

    // B2: mới lưu Hợp đồng
    await updateContract(contractId, values);
    notification.success({
      message: "Cập nhật thành công",
    });
    setLoading(false);
    nav("/public", {
      // replace: true,
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
    notification.error({
      message: "Cập nhật thất bại",
    });
  };

  const styleFormItem = { marginBottom: 0 };

  return (
    <div
      className={styles.containerBg}
      style={{
        verticalAlign: "middle",
        padding: 10,
      }}
    >
      <Row
        style={{
          maxWidth: 980,
          margin: "auto",
          borderRadius: 6,
          backgroundColor: "#ededed",
        }}
      >
        <Col span={16}>
          <Card title={"Soạn thảo Hợp đồng dành cho Khách hàng "}>
            {item?.customerName && <h4>Xin chào {item.customerName},</h4>}

            <Form
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              layout="vertical"
              disabled={loading}
              form={form}
            >
              <Descriptions bordered column={2} size="small">
                <Descriptions.Item label="Tên HĐ">
                  {item?.name}
                </Descriptions.Item>
                <Descriptions.Item label="Số HĐ">
                  {item?.soHopDong || "HD00053447"}
                </Descriptions.Item>

                <Descriptions.Item label="Tên khách">
                  {item?.customerName}
                </Descriptions.Item>
                <Descriptions.Item label="Email">
                  {item?.customerEmail}
                </Descriptions.Item>

                <Descriptions.Item label="Địa chỉ">
                  {item?.customerAddress}
                </Descriptions.Item>
                <Descriptions.Item label="Số ĐT">
                  {item?.customerPhone}
                </Descriptions.Item>

                <Descriptions.Item label="Nội dung HĐ" span={2}>
                  <Form.Item name="description" style={styleFormItem}>
                    <TextArea rows={4} />
                  </Form.Item>
                </Descriptions.Item>

                <Descriptions.Item label="Tổng giá trị" span={2}>
                  <Form.Item name="tongGiaTriHopDong" style={styleFormItem}>
                    <InputNumber
                      min={0}
                      style={{ width: "100%" }}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      // @ts-ignore
                      parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
                    />
                  </Form.Item>
                </Descriptions.Item>

                <Descriptions.Item label="Ngày bắt đầu (dự kiến)">
                  <Form.Item name="ngayBatDauDuKien" style={styleFormItem}>
                    <DatePicker />
                  </Form.Item>
                </Descriptions.Item>

                <Descriptions.Item label="Ngày kết thúc (dự kiến)">
                  <Form.Item name="ngayKetThucDuKien" style={styleFormItem}>
                    <DatePicker />
                  </Form.Item>
                </Descriptions.Item>

                <Descriptions.Item label="Điều khoản bên A" span={2}>
                  <Form.Item name="dieuKhoanBenA" style={styleFormItem}>
                    <TextArea rows={4} />
                  </Form.Item>
                </Descriptions.Item>

                <Descriptions.Item label="Điều khoản bên B" span={2}>
                  <Form.Item name="dieuKhoanBenB" style={styleFormItem}>
                    <TextArea rows={4} />
                  </Form.Item>
                </Descriptions.Item>

                <Descriptions.Item label="Thông tin bán hàng" span={2}>
                  <Form.Item name="thongTinBanHang" style={styleFormItem}>
                    <TextArea rows={4} />
                  </Form.Item>
                </Descriptions.Item>

                <Descriptions.Item label="Thông tin thanh toán" span={2}>
                  <Form.Item name="thongTinThanhToan" style={styleFormItem}>
                    <TextArea rows={4} />
                  </Form.Item>
                </Descriptions.Item>

                <Descriptions.Item span={2} label="Thông tin bảo hành">
                  <Form.Item name="thongTinBaoHanh" style={styleFormItem}>
                    <TextArea rows={4} />
                  </Form.Item>
                </Descriptions.Item>

                <Descriptions.Item label="Thông tin giao nhận hàng" span={2}>
                  <Form.Item name="thongTinGiaoNhanHang" style={styleFormItem}>
                    <TextArea rows={4} />
                  </Form.Item>
                </Descriptions.Item>
              </Descriptions>

              <Form.Item label="Ghi chú phiên chỉnh sửa" name="changeNote">
                <TextArea rows={4} placeholder="Nhập nội dung mô tả" />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  icon={<SendOutlined />}
                >
                  Gửi thông tin cập nhật
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col span={8}>
          <Descriptions bordered column={1} size="small">
            <Descriptions.Item label="Ngày tạo">
              {item?.created && formatDateTime(item.created)}
            </Descriptions.Item>
            <Descriptions.Item label="Sửa lần cuối">
              {item?.updated && formatDateTime(item.updated)}
            </Descriptions.Item>
          </Descriptions>
          <Divider orientation="left">
            <h4>Lịch sử sửa đổi</h4>
          </Divider>
          {item?.id && <ListHistoryOnContract contractId={item.id} />}
        </Col>
      </Row>
    </div>
  );
};

export default UpdateContractByCustomer;
