import React from 'react';
import {Space, Table, Tag} from 'antd';
import type {ColumnsType} from 'antd/es/table';
import ConfigLayout from "./components/ConfigLayout";
import {Link, useNavigate} from "react-router-dom";

interface DataType {
	key: string;
	name: string;
	age: number;
	address: string;
	tags: string[];
}


const data: DataType[] = [
	{
		key: '1',
		name: 'Hợp đồng cung ứng nguyên vật liệu quý 1/2023',
		age: 32,
		address: '10:23 05/12/2000',
		tags: ['Đang trình duyệt'],
	},
	{
		key: '2',
		name: 'HĐ mua trang thiết bị máy tính cho Chi nhánh Hà Nội',
		age: 42,
		address: '08:30 06/03/2021',
		tags: ['Khách đang biên soạn'],
	},
	{
		key: '3',
		name: 'Hợp đồng bảo trì máy photocopy cho công ty A',
		age: 32,
		address: '16:05 20/10/2022',
		tags: ['Nháp'],
	}, {
		key: '4',
		name: 'Hợp đồng cung ứng nguyên vật liệu quý 2/2023',
		age: 32,
		address: '10:23 05/12/2000',
		tags: ['Đang trình duyệt'],
	},
	{
		key: '5',
		name: 'HĐ mua trang thiết bị máy tính cho Chi nhánh Đà Nẵng',
		age: 42,
		address: '08:30 06/03/2021',
		tags: ['Khách đang biên soạn'],
	},
	{
		key: '6',
		name: 'Hợp đồng bảo trì máy photocopy cho công ty Phúc Thịnh',
		age: 32,
		address: '16:05 20/10/2022',
		tags: ['Nháp'],
	}
	, {
		key: '7',
		name: 'Hợp đồng cung ứng nguyên vật liệu quý 3/2023',
		age: 32,
		address: '10:23 05/12/2000',
		tags: ['Đang trình duyệt'],
	},
	{
		key: '8',
		name: 'HĐ mua trang thiết bị máy tính cho Chi nhánh Long An',
		age: 42,
		address: '08:30 06/03/2021',
		tags: ['Khách đang biên soạn'],
	},
	{
		key: '9',
		name: 'Hợp đồng bảo trì máy photocopy cho công ty Phát Triển',
		age: 32,
		address: '16:05 20/10/2022',
		tags: ['Nháp'],
	},
];

const TableDemo: React.FC = () => {

	const nav = useNavigate()
		// /config/update/qk5e45r42vtx7qk

	const columns: ColumnsType<DataType> = [
		{
			title: 'Số HĐ',
			dataIndex: 'key',
			key: 'key',
			render: (text) => <Link to="/config/update/qk5e45r42vtx7qk">HD#000{text}</Link>,
		},
		{
			title: 'Tên HĐ',
			dataIndex: 'name',
			key: 'name',
			render: (text) => <Link to="/config/update/qk5e45r42vtx7qk">{text}</Link>,
		},
		{
			title: 'Trạng thái',
			key: 'tags',
			dataIndex: 'tags',
			render: (_, {tags}) => (
				<>
					{tags.map((tag) => {
						let color = tag.length < 5 ? 'geekblue' : 'green';
						if (tag === 'Đang trình duyệt') {
							color = 'volcano';
						}
						return (
							<Tag color={color} key={tag}>
								{tag.toUpperCase()}
							</Tag>
						);
					})}
				</>
			),
		},
		{
			title: 'Ngày tạo',
			dataIndex: 'address',
			key: 'address',
		},

		{
			title: 'Action',
			key: 'action',
			render: () => (
				<Space size="middle">
					<a>Sửa</a>
					<a>Xóa</a>
				</Space>
			),
		},
	];



	return <ConfigLayout>
		<div style={{marginTop: 20}}>
			<Table columns={columns} dataSource={data} pagination={false}/>
		</div>
	</ConfigLayout>;
}

export default TableDemo;