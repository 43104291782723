import type { FC } from "react";
import { Button, Result } from "antd";

const ResultSuccess: FC = () => (
  <div style={{ backgroundColor: "#fff", margin: "auto", paddingTop: 50 }}>
    <Result
      status="success"
      title="Cập nhật thông tin hợp đồng thành công!"
      subTitle="Thông tin Hợp đồng của quý khách đã được gửi cho chuyên viên của chúng tôi để cập nhật."
      // extra={[
      //   <Button type="primary" key="console">
      //     Go Console
      //   </Button>,
      //   <Button key="buy">Buy Again</Button>,
      // ]}
    />
  </div>
);

export default ResultSuccess;
