import {
  Breadcrumb,
  Button,
  Col,
  Descriptions,
  Divider,
  Form,
  notification,
  Row,
} from "antd";
import { useEffect, useState } from "react";
import { useHref, useParams } from "react-router-dom";
import dayjs from "dayjs";

import { FolderOpenOutlined, EditOutlined } from "@ant-design/icons";

import { getContract, updateContract } from "../../contract.api-service";
import { Contract } from "../../Contract.entity";
import ListHistoryOnContract from "./components/ListHistoryOnContract";
import ContractForm from "./components/ContractForm";
import ConfigLayout from "./components/ConfigLayout";
import { formatDateTime } from "../../../formatDateTime.ultis";
import {sendEmail} from "./ultils/send-email";

const UpdateContract: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState<Contract>();
  const { contractId } = useParams();

  const linkToListContract = useHref("/config");

  useEffect(() => {
    if (contractId) getContractNeedUpdate(contractId);
  }, [contractId]);

  const getContractNeedUpdate = (contractId: string) => {
    setLoading(true);
    getContract(contractId)
      .then((result) => {
        setItem(result);

        if (result.ngayBatDauDuKien)
          result.ngayBatDauDuKien = dayjs(result.ngayBatDauDuKien);
        if (result.ngayKetThucDuKien)
          result.ngayKetThucDuKien = dayjs(result.ngayKetThucDuKien);
        // điền giá trị vào form
        form.setFieldsValue(result);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const onFinish = async (values: Omit<Contract, "id">) => {
    if (!contractId) return;

    setLoading(true);
    await updateContract(contractId, values);
    notification.success({
      message: "Cập nhật thành công",
    });
    setLoading(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
    notification.error({
      message: "Cập nhật thất bại",
    });
  };

  const shareContract = async () => {
    if(!item) {
      notification.error({
        message: "Không tìm thấy hợp đồng",
      })
      return;
    };
    sendEmail(item).then(()=> notification.success({
      message: "Gửi emai mời thành công",
    })).catch(() => notification.error({
      message: "Gửi emai mời thất bại",
    }));
  }

  return (
    <ConfigLayout>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item href={linkToListContract}>
          <FolderOpenOutlined /> Hợp đồng
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <EditOutlined /> Cập nhật thông tin Hợp đồng
        </Breadcrumb.Item>
      </Breadcrumb>

      <Row gutter={14}>
        <Col xs={24} sm={24} md={18} lg={18} xl={18} >
          <Form
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
            disabled={loading}
            form={form}
          >
            <ContractForm />

            <Form.Item wrapperCol={{ offset: 12, span: 24 }}>
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>

              <Button loading={loading} onClick={shareContract}>
                Share
              </Button>
            </Form.Item>
          </Form>
        </Col>
        <Col  xs={24} sm={24} md={6} lg={6} xl={6}>
          <Descriptions bordered column={1} size="small">
            <Descriptions.Item label="Ngày tạo">
              {item?.created && formatDateTime(item.created)}
            </Descriptions.Item>
            <Descriptions.Item label="Sửa lần cuối">
              {item?.updated && formatDateTime(item.updated)}
            </Descriptions.Item>
          </Descriptions>
          <Divider orientation="left">
            <h5>Lịch sử khách hàng sửa đổi</h5>
          </Divider>
          {contractId && <ListHistoryOnContract contractId={contractId} />}
        </Col>
      </Row>
    </ConfigLayout>
  );
};

export default UpdateContract;
