import {formatDateTime} from "../../../../formatDateTime.ultis";
import {Contract} from "../../../Contract.entity";

export function contractEmailTemplate(contract: Contract, linkShare: string) {
	return `
    <table style="border: 1px solid #ddd; padding: 2px 2px; border-collapse: collapse; min-width: 100%">
    <thead>
        <tr style="text-align: center;">
            <th style=" border: 1px solid #ddd;" colspan="2">Contract Form / Thông tin Hợp đồng</th>
        </tr>
    </thead>
    <tbody>
    
    </tr>
    <tr>
	    <td width="35%" style=" border: 1px solid #ddd; padding: 5px 10px">
	    Name / Tên
	    </td>
	    <td style=" border: 1px solid #ddd; padding: 5px 10px">${contract.name}</td>
    </tr>
    <tr>
	    <td width="35%" style=" border: 1px solid #ddd; padding: 5px 10px">
	    Descripton / Nội dung
	    </td>
	    <td style=" border: 1px solid #ddd; padding: 5px 10px">${contract.description}</td>
    </tr>
    <tr>
    <td width="35%" style=" border: 1px solid #ddd; padding: 5px 10px">
    Created at / Tạo mới         
    </td>
    <td style=" border: 1px solid #ddd; padding: 5px 10px">${
		contract.created ? formatDateTime(contract.created) : ''
	}</td>
    </tr>
    <tr>
    <td width="35%" style=" border: 1px solid #ddd; padding: 5px 10px">
    Updated at / Cập nhật       
    </td>
    <td style=" border: 1px solid #ddd; padding: 5px 10px">${
		contract.updated ? formatDateTime(contract.updated) : ''
	}</td>
    </tr>
    <tr>
    <td width="35%" style=" border: 1px solid #ddd; padding: 5px 10px">
    View / Xem & biên soạn Hợp đồng   
    </td>
    <td style=" border: 1px solid #ddd; padding: 5px 10px"><a href="${linkShare}">Click here to view / Bấm vào đây để biên soạn hợp đồng</a></td>
    </tr>
    </tbody>
    <tfooter>
    <tr>
      <td colspan="2">
      <div style=" font-size: 10px; padding-left: 5px;  opacity: 0.6;">
          <p>Email này và các tài liệu kèm theo, nếu có, có thể chứa các thông tin mật/bản quyền của ICT24h và chỉ nhằm mục đích sử dụng cho đúng người nhận. Nếu bạn nhận được email này do sai sót, xin vui lòng báo lại người gửi, xóa email này cũng như tài liệu đính kèm và các trao đổi liên quan trên tất cả các thiết bị, không sao chép hoặc chuyển tiếp email dưới bất kỳ hình thức nào cho bất kỳ đối tượng nào khác. Chúng tôi không chịu trách nhiệm cho bất kỳ thiệt hại nào phát sinh từ việc email này bị sử dụng sai mục đích.</p>
       <div>
      </td>  
    </tr>
</tfooter>
    </table>
    `;
}
