import React, { useState } from "react";
import { Form, Modal } from "antd";
import ContractForm from "./ContractForm";
import { createContract } from "../../../contract.api-service";

interface IProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  reFetchData: () => Promise<void>;
}

const ModalCreateContract: React.FC<IProps> = ({
  isOpen,
  setIsOpen,
  reFetchData,
}) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    setLoading(true);
    await createContract(values);
    reFetchData();
    setIsOpen(false);
    setLoading(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Modal
      title="Tạo mới Hợp đồng"
      centered
      style={{ top: 15 }}
      open={isOpen}
      onOk={() => form.submit()}
      onCancel={() => setIsOpen(false)}
      width={680}
    >
      <Form
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
        disabled={loading}
        form={form}
      >
        <ContractForm />
      </Form>
    </Modal>
  );
};

export default ModalCreateContract;
