import React from 'react';
import { FolderOpenOutlined, CheckCircleOutlined, EditOutlined, BarChartOutlined  } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Layout, Menu, theme } from 'antd';
import {useNavigate} from "react-router-dom";

const {  Sider } = Layout;
interface IProps {
   children: React.ReactNode;
 }



const ConfigLayout: React.FC<IProps> = (props) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

const nav = useNavigate()

  const navToDemo = () => {
    nav("/config-table-demo")
  }

  const items2: MenuProps['items'] = [
    {
      key: 'list-contract',
      label: 'Danh sách hợp đồng',
      icon: React.createElement(FolderOpenOutlined),
      onClick: () => nav('/config'),
    },
    {
      key: 'fake-contract-approval',
      label:' Phê duyệt HĐ',
      icon: React.createElement(CheckCircleOutlined),
      children: [
        {key: 'fake-contract-approval-1', label: 'Chờ phê duyệt', onClick: navToDemo },
        {key: 'fake-contract-approval-2', label: 'Đã phê duyệt', onClick: navToDemo },
        {key: 'fake-contract-approval-3', label: 'Đã từ chối', onClick: navToDemo },
      ]
    },
    {
      key: 'fake-contract-edit',
      label:' Biên soạn HĐ',
      icon: React.createElement(BarChartOutlined),
      children: [
        {key: 'fake-contract-edit-1', label: 'Đang biên soạn', onClick: navToDemo },
        {key: 'fake-contract-edit-2', label: 'Đang trình duyệt', onClick: navToDemo },
        {key: 'fake-contract-edit-3', label: 'Đã được duyệt', onClick: navToDemo },
      ]
    },
    {
      key: 'fake-contract-report',
      label:' Báo cáo - Thống kê',
      icon: React.createElement(FolderOpenOutlined),
      children: [
        {key: 'fake-contract-report-1', label: 'Báo cáo HĐ', onClick: navToDemo },
        {key: 'fake-contract-report-2', label: 'Báo cáo khách hàng', onClick: navToDemo },
        {key: 'fake-contract-report-3', label: 'Thống kê phòng ban', onClick: navToDemo },
      ]
    }

  ];

  return (
    <Layout>
      <Layout>
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          width={200} style={{ background: colorBgContainer }}>
          <Menu
            mode="inline"
            defaultSelectedKeys={['list-contract']}
            defaultOpenKeys={['fake-contract-approval','fake-contract-edit','fake-contract-report']}
            style={{ height: '100%'}}
            items={items2}
          />
        </Sider>
        <Layout style={{ padding: '0 24px 24px' }}>
          {props.children}
        </Layout>
      </Layout>
    </Layout>
  );
};

export default ConfigLayout;