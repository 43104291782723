import {Grid, Modal} from "antd";
import ReactDiffViewer from "react-diff-viewer";
import { formatDateTime } from "../../../../formatDateTime.ultis";
import { Contract } from "../../../Contract.entity";


const { useBreakpoint } = Grid;

interface IProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;

  before?: string;
  after?: string;
}
// diff?: string | null
//   diffAfter?
export default function DiffCompareContract({
  before,
  after,
  isOpen,
  setIsOpen,
}: IProps) {

const screens = useBreakpoint();
const splitView = screens["md"];


  const beforeObj: Partial<Contract> = JSON.parse(before || "{}");
  const afterObj: Partial<Contract> = JSON.parse(after || "{}");


  return (
    <Modal
      centered
      style={{ top: 15, maxWidth: 680 }}
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      footer={null}
    >
      <p>Nội dung hợp đồng</p>
      <ReactDiffViewer
        oldValue={beforeObj.description}
        newValue={afterObj.description}
        splitView={splitView}
      />

      <p>Tổng giá trị</p>
      <ReactDiffViewer
        oldValue={beforeObj.tongGiaTriHopDong?.toString()}
        newValue={afterObj.tongGiaTriHopDong?.toString()}
        hideLineNumbers
        splitView={splitView}
      />

      <p>Ngày bắt đầu (dự kiến)</p>
      <ReactDiffViewer
        oldValue={
          beforeObj.ngayBatDauDuKien
            ? formatDateTime(beforeObj.ngayBatDauDuKien as string)
            : ""
        }
        newValue={
          afterObj.ngayBatDauDuKien
            ? formatDateTime(afterObj.ngayBatDauDuKien as string)
            : ""
        }
        hideLineNumbers
        splitView={splitView}
      />

      <p>Ngày kết thúc (dự kiến)</p>
      <ReactDiffViewer
        oldValue={
          beforeObj.ngayKetThucDuKien
            ? formatDateTime(beforeObj.ngayKetThucDuKien as string)
            : ""
        }
        newValue={
          afterObj.ngayKetThucDuKien
            ? formatDateTime(afterObj.ngayKetThucDuKien as string)
            : ""
        }
        hideLineNumbers
        splitView={splitView}
      />

      <p>Điều khoản bên A</p>
      <ReactDiffViewer
        oldValue={beforeObj.dieuKhoanBenA}
        newValue={afterObj.dieuKhoanBenA}
        splitView={splitView}
      />

      <p>Điều khoản bên B</p>
      <ReactDiffViewer
        oldValue={beforeObj.dieuKhoanBenB}
        newValue={afterObj.dieuKhoanBenB}
        splitView={splitView}
      />

      <p>Thông tin bán hàng</p>
      <ReactDiffViewer
        oldValue={beforeObj.thongTinBanHang}
        newValue={afterObj.thongTinBanHang}
        splitView={splitView}
      />

      <p>Thông tin thanh toán</p>
      <ReactDiffViewer
        oldValue={beforeObj.thongTinThanhToan}
        newValue={afterObj.thongTinThanhToan}
        splitView={splitView}
      />

      <p>Thông tin giao nhận hàng</p>
      <ReactDiffViewer
        oldValue={beforeObj.thongTinGiaoNhanHang}
        newValue={afterObj.thongTinGiaoNhanHang}
        splitView={splitView}
      />
    </Modal>
  );
}
