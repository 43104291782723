import "dayjs/locale/vi";
import locale from "antd/locale/vi_VN";

import Routers from "./Routers";
import { ConfigProvider } from "antd";

function App() {
  return (
    <ConfigProvider locale={locale}>
      <Routers />
    </ConfigProvider>
  );
}

export default App;
