import { useEffect, useState } from "react";
import {
  UserOutlined,
  ClockCircleOutlined,
  CommentOutlined,
  DiffTwoTone,
} from "@ant-design/icons";
import { getListCustomerEditHistoryByContractId } from "../../../contract.api-service";
import { CustomerEditHistory } from "../../public/customer-edit-histoy/CustomerEditHistory";
import { Button, Timeline } from "antd";
import { formatDateTime } from "../../../../formatDateTime.ultis";
import DiffCompareContract from "./DiffCompare";

interface Props {
  contractId: string;
}

export default function ListHistoryOnContract({ contractId }: Props) {
  const [items, setItems] = useState<CustomerEditHistory[]>([]);
  const [openDiffModal, setOpenDiffModal] = useState<boolean>(false);
  const [diff, setDiff] = useState<{ before?: string; after?: string }>({});

  useEffect(() => {
    getListCustomerEditHistoryByContractId(contractId).then((result) => {
      setItems(result.items);
    });
  }, [contractId]);

  const clickShowDiff = (before: string, after: string) => {
    setDiff({ before, after });
    setOpenDiffModal(true);
  };

  return (
    <>
      <DiffCompareContract
        before={diff.before}
        after={diff.after}
        isOpen={openDiffModal}
        setIsOpen={setOpenDiffModal}
      />
      <Timeline>
        {items.map((history) => (
          <Timeline.Item key={history.id}>
            <UserOutlined /> <b>{history.customerName}</b> <br />
            <ClockCircleOutlined /> {formatDateTime(history.created)} <br />
            {history.description && (
              <div>
                <CommentOutlined />{" "}
                <span style={{ color: "#2196f3" }}>{history.description}</span>
              </div>
            )}
            {history.diff && history.diffAfter ? (
              <Button
                onClick={() => {
                  // @ts-ignore
                  clickShowDiff(history.diff, history.diffAfter);
                }}
                icon={<DiffTwoTone />}
                size="small"
              >
                Xem nội dung thay đổi
              </Button>
            ) : null}
          </Timeline.Item>
        ))}
      </Timeline>
    </>
  );
}
