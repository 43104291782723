import PocketBase from "pocketbase";
import { Contract } from "./Contract.entity";
import { CustomerEditHistory } from "./pages/public/customer-edit-histoy/CustomerEditHistory";
const pb = new PocketBase(process.env.REACT_APP_API_ENDPOINT);

const ContractEntityName = "contract";
const CustomerEditHistoryEntityName = "customerEditHistory";

export const getListContract = () => {
  return pb.collection(ContractEntityName).getList<Contract>(1, 500, {
    sort: "-created",
  });
};

export const createContract = (body: Omit<Contract, "id">) => {
  return pb.collection(ContractEntityName).create<Contract>(body);
};

export const getContract = (id: string) => {
  return pb.collection(ContractEntityName).getOne<Contract>(id);
};

export const updateContract = (id: string, data: Omit<Contract, "id">) => {
  return pb.collection(ContractEntityName).update<Contract>(id, data);
};

export const getListCustomerEditHistoryByContractId = (contractId: string) => {
  return pb
    .collection(CustomerEditHistoryEntityName)
    .getList<CustomerEditHistory>(1, 500, {
      filter: 'contract = "' + contractId + '"',
      sort: "-created",
    });
};

export const getListCustomerEditHistory = () => {
  return pb
    .collection(CustomerEditHistoryEntityName)
    .getList<CustomerEditHistory>(1, 500, {
      sort: "-created",
    });
};

export const createCustomerEditHistory = (
  body: Omit<CustomerEditHistory, "id">
) => {
  return pb
    .collection(CustomerEditHistoryEntityName)
    .create<CustomerEditHistory>(body);
};

/**
 * Các ví dụ mẫu
 *
  // Returns a list with all records batch fetched at once.
🔓 pb.collection(collectionIdOrName).getFullList(batch = 200, queryParams = {});

// Returns the first found record matching the specified filter.
🔓 pb.collection(collectionIdOrName).getFirstListItem(filter, queryParams = {});

// Returns a single record by its id.
🔓 pb.collection(collectionIdOrName).getOne(recordId, queryParams = {});

// Creates (aka. register) a new record.
🔓 pb.collection(collectionIdOrName).create(bodyParams = {}, queryParams = {});

// Updates an existing record by its id.
🔓 pb.collection(collectionIdOrName).update(recordId, bodyParams = {}, queryParams = {});

// Deletes a single record by its id.
🔓 pb.collection(collectionIdOrName).delete(recordId, queryParams = {});
 */
