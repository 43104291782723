import { Card, Col, DatePicker, Form, Input, InputNumber, Row } from "antd";
const { TextArea } = Input;

export default function ContractForm() {
  return (
    <>
      <Row gutter={6}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Card title="Thông tin cơ bản" size="small">
            <Form.Item
              label="Tên hợp đồng"
              name="name"
              rules={[{ required: true, message: "Vui lòng nhập Tên HĐ!" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="Số hợp đồng" name="soHopDong">
              <Input placeholder="Nhập số HD" />
            </Form.Item>

            <Form.Item name="tongGiaTriHopDong" label="Tổng giá trị">
              <InputNumber
                min={0}
                style={{ width: "100%" }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                // @ts-ignore
                parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
              />
            </Form.Item>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Card title="Thông tin khách hàng" size="small">
            <Form.Item label="Tên khách hàng" name="customerName">
              <Input />
            </Form.Item>

            <Form.Item label="Email" name="customerEmail" rules={[{type: 'email', required: true, message: "Vui lòng nhập email khách hàng" }]}>
              <Input />
            </Form.Item>

            <Form.Item label="Số điện thoại" name="customerPhone">
              <Input />
            </Form.Item>
          </Card>
        </Col>
      </Row>
      <Row gutter={6} style={{ marginTop: 10 }}>
        <Col span={24}>
          <Card size="small">
            <Form.Item label="Nội dung HĐ" name="description">
              <TextArea rows={4} />
            </Form.Item>

            <Row gutter={6}>
              <Col span={12}>
                <Form.Item
                  name="ngayBatDauDuKien"
                  label="Ngày bắt đầu (dự kiến)"
                >
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="ngayKetThucDuKien"
                  label="Ngày kết thúc (dự kiến)"
                >
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item name="dieuKhoanBenA" label="Điều khoản bên A">
              <TextArea rows={4} />
            </Form.Item>

            <Form.Item name="dieuKhoanBenB" label="Điều khoản bên B">
              <TextArea rows={4} />
            </Form.Item>

            <Form.Item name="thongTinBanHang" label="Thông tin bán hàng">
              <TextArea rows={4} />
            </Form.Item>

            <Form.Item name="thongTinThanhToan" label="Thông tin thanh toán">
              <TextArea rows={4} />
            </Form.Item>

            <Form.Item name="thongTinBaoHanh" label="Thông tin bảo hành">
              <TextArea rows={4} />
            </Form.Item>

            <Form.Item
              name="thongTinGiaoNhanHang"
              label="Thông tin giao nhận hàng"
            >
              <TextArea rows={4} />
            </Form.Item>
          </Card>
        </Col>
      </Row>
    </>
  );
}
